// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}

$color-background-transparency: rgba(0, 0, 0, 0.5);

.navbar-wrapping-div{
    background-color: rgb(252, 251, 247);
    height: 70px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 15;

    .logo-div-bottom{
        display: flex;
        justify-content: center;

        .siko-logo-bottom{
            width: 88px;
            height: auto;
            padding-top: 30px;
        }
    }

    .icon{
        justify-content: center;
        right: 20px;
        display: flex;
        align-items: center;
        font-size: x-large;
        right: 20px;
        top: 23px;
        position: absolute;

        @include tablet{
            display: none;
        }
    }
    
    .logo-div{
        display: flex;
        flex-direction: row;        
        align-items: center;
        margin-left: 30px;
        
        .siko-logo{
            float: left;
            width: 45px;
            height: 51px;
            text-align: center;
        }    
    }

    .mobile{
        display: flex;
        flex-direction: column;
    }

    .navbar-ul-div{
        display: flex;
        justify-content: center;
        
        @include tablet{
            display: flex;
            flex-direction: row;        
            align-items: center;
        }
        
        .navbar-ul{
            right: 5px;
            display: flex;
            list-style-type: none;
            padding: 0 50px;
            text-align: center;

            @include tablet{
                right: 5px;
                display: flex;
                align-items: center;
                float: right;
            }
    
            .navbar-li{
                color: rgb(13, 38, 108);
                padding: 15px 0;
                font-size: 21px;

                @include tablet{
                    font-size: 16px;    
                    margin-right: 10px;
                    list-style: none;
                    padding-right: 20px;
                    border: none;
                }
            }
        }
    }

    .menu-class{
        position: fixed;
        background-color: rgb(252, 251, 247);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 50;
        box-shadow: 0 0 3px lightgray;
        padding: 3px;
        padding-top: 40px;
        
        .exit-div{
            position: absolute;
            margin: 30px;
            right: 0;
            top: 0;
    
            .exit{
                color: rgb(13, 38, 108);
            }
        }
        .exit:hover{
            cursor: pointer;
        }
    }
    
    .menu-mask{
        background-color: $color-background-transparency;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 50;
        right: 5px;
        display: flex;
        align-items: center;
        float: right;
    }
}