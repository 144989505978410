// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}

.cover-div{
    display: flex;
    justify-content: center;
    position: relative;
    
    .modal-main-wrapper{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 20vh;
        border-radius: 10px;
        width: 95%;
        z-index: 30;
        background-color: rgb(13, 38, 108,); 
        justify-content: center;
        animation: fadeinout linear 1 forwards;
        animation-delay: 0ms;

        @keyframes fadeinout {
            0% { opacity: 0; }
            50% { opacity: 1; }
        }
        
        @include mini-desktop{
            width: 90%;
            flex-direction: row;
        }

        .close-modal{
            position: absolute;
            margin: 10px;
            right: 0;
            top: 0;
    
            .exit{
                color: #FCFBF7;
                
                @include mini-desktop{
                    color: rgb(13, 38, 108);
                }
            }
        }

        .close-modal:hover{
            cursor: pointer;
        }
    
        .modal-div{
            position: relative;
            border-radius: 10px;
            color: #FCFBF7;
            width: 80%;
            top: 50%;
            margin: 20px;
            padding: 15px;

            li{
                font-size: 21px;
                line-height: 1.8em;
            }
        }
    }

    .image-div{
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        @include mini-desktop{
            margin-bottom: 0;
        }

        .image{
            object-fit: cover;
            height: 350px;
            
            @include mini-desktop{
                height: 100%;
                border-top-right-radius:   10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}

.hidden{
    display: none;
}