.hero-block{
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .hero-image-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-size: cover;
        
        .hero-filter{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(13, 38, 108, 0.7 );          
        }
        .hero-img{
            width: 100%;
            min-height: 100%;
        }
    }

    .hero-content{
        height: 100%;
        color: white;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 30px;

        .hero-ingress{
            font-size: 19px;
            
            @include tablet{
                font-size: 22px;
            }
        }
        
        .hero-text{
            margin: 0 auto;
            width: 60%;
        }
    }

    .hero-button-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 40px 0;

        a{
            color: #fcfbf7;
        }
        a:visited{
            color: #fcfbf7;
        }

        .hero-button{
        background-color: transparent;
        color: #fcfbf7;
        border: none;
        text-decoration: underline;
        font-size: 30px;
        margin: 20px;
        padding: 5px;
        outline: none;
        }
        .hero-button:hover{
            cursor: pointer;
        }
        .hero-button:visited{
            cursor: pointer;
        }
    }
}