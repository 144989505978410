// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
.extra-padding {
    padding: 0 5em;
}


.links.organisations a {

    &:hover {
        font-weight: 600;
    }

}

.split-page-block{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    min-height: 100vh;

    
    
    @include tablet{
        justify-content: start;
        flex-direction: row;
    }

    .split-page-image-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        background-position: center;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: contain;
        
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .split-page-content-Left{
        left: 0;
    }
    .split-page-content-Right{
        left: 0;

        @include tablet{
            transform: translate(100%);
        }
        h2{
            padding-left: 40px;
        }

        .button-right{
            margin-left: 40px;
            background-color: transparent;
            color: red;
            border: none;
            text-decoration: underline;
            font-size: 30px;
            outline: none;
            padding: 0;
        }
        .button-right:hover{
            cursor: pointer;
        }
        a, a:visited{
            color: #fcfbf7;
        }
    }
    .split-page-content-White{
        background-color: rgba(252, 251, 247, 0.8);
    }
    .split-page-content-Blue{
        background-color: rgba(13, 38, 108, 0.7 ); 
    }

    .split-page-content-wrapper{
        justify-content: center;
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
         
        @include tablet{
            width: 50%;
        }
    
        .split-page-text-wrapper{

            .text-div{
                margin: 30px 0;

                li{
                    font-family: 'Raleway', sans-serif;
                    font-size: 25px;
                }
            }

        }
       
        .split-page-text{
            height: 100%;


           

            h2{
                display: inline-block;
                margin: 10px 20px 10px 0;

                @include tablet{
                    margin-top: 60px;
                }
            }

            .split-page-button-div{
                margin-top: 30px;
                
                .button{
                    background-color: transparent;
                    color: #fcfbf7;
                    border: none;
                    text-decoration: underline;
                    font-size: 30px;
                    outline: none;
                    padding: 0;
                }
                .button:hover{
                    cursor: pointer;
                }
                a, a:visited{
                    color: #fcfbf7;
                }
            }
        }

        .split-page-logos{
            display: flex;
            justify-content: space-evenly;
            left: 0;
            flex-wrap: wrap;
            margin-bottom: 30px;
    
            .logoImages-div{
                margin: 20px 30px;
    
                .logoImage{
                    height: 200px;
                }
            }
        }
        .split-page-logos-Left{
            display: flex;
            justify-content: center;
            transform: translate(-100%);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            
            .logoImages-div{
                
                img{
                    width: 200px;
                }
            }
        }
        .split-page-logos-Right{
            position: absolute;
            display: flex;
            justify-content: center;
            width: 50%;
            right: 0;
            bottom: 0;
            
            .logoImages-div{
                
                img{
                    width: 200px;
                }
            }
        }
    }
}

.split-page-text-content-right{
    width: 100%;

}



.split-page-text-color-White{
    color: white;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.split-page-text-color-Blue{
    color: rgb(13, 38, 108,); 
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}