// Mixins
@mixin mini-tablet {
    @media (min-width: 780px) { @content; }
}
@mixin tablet {
    @media (min-width: 820px) { @content; }
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}
@mixin desktop {
    @media (min-width: 1200px) { @content; }
}
.grey{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
}
.showLoader{
    z-index: 31;
}

.loader-wrapper{
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;

    .loader {
        position: fixed;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #0D276C;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.sent-form-cover-screen-div{
    position: absolute;
    display: flex;
    top: 40px;
    left: 21px;
    width: 90%;
    z-index: 30;
    justify-content: center;
    
    @include tablet{
        width: 95%;
        left: 9px;
        width: 97%;
    }
}

.form-wrapping-drop-down{
    display: flex;
    flex-direction: column;

    @include mini-tablet{
        flex-direction: row;
        flex-wrap: wrap;
    }

    .form-drop-down-div{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        padding-bottom: 15px;

        @include mini-tablet{
            width: 50%;
        }

        .span-title{
            font-size: 22px;
            padding-bottom: 20px;
        }

        .lokalt{
            font-size: 19px;
        }

        .locally-empl-option{
            padding-left: 30px;
        }

        .checkbox-row{
            position: relative;
            padding-right: 35px;
            display: flex;
            flex-direction: row;
            margin: 5px;

            .form-checkbox {
                opacity: 0;
                height: 0;
                width: 0;
            }

            .newCheckbox{
                background-color: #FCFBF7;
                border: #0D276C solid 2px;
                border-radius: 5px;
                color: white;
                outline: none;
                min-width: 17px;
                height: 17px;
                margin-top: 1px;
                color: #0D276C;

                .fas{
                    position: absolute;
                    top: 3px;
                }
            }

            .newCheckbox:hover{
                background-color: rgb(218, 218, 218);
            }
        }

        .family-members{
            background-color: #FCFBF7;
            border: #0D276C solid 2px;
            width: 40px;
            margin-right: 10px;
            border-radius: 5px;
            color: #0D276C;
            outline: none;
            margin: 5px;
        }

        .select-container{
            padding: 7px;
            border-radius: 5px;
            border: white solid 2px;
            background: transparent;
            color: white;
            background-repeat: no-repeat;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-position-x: 5px;
            background-size: 15px;

            option{
                background-color: rgb(58, 58, 255);
            }
        }

        .textarea{
            border: #0D276C solid 2px;
            border-radius: 5px;
            background-color: #FCFBF7;

        }
    }
}

.checkbox{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.sent-form{
    position: relative;
    padding: 40px;
    background-color:rgb(252, 251, 247);
    border-radius: 10px;
    color: rgb(13, 38, 108);
    width: 76%;
    top: 50%;

    @include desktop{
        width: 70%;
    }

    .sent-form-content{
        margin-top: 50px;
        text-align: center;

        .sent-form-h2{
            padding: 40px 5px 20px 5px;
        }
        .sent-form-p{
            padding: 20px;
            padding-bottom: 40px;
        }
    }

    .exit-div{
        position: absolute;
        margin: 10px;
        right: 0;
        top: 0;

        .exit{
            color: rgb(13, 38, 108);
        }
    }
    .exit:hover{
        cursor: pointer;
    }
}

.sent-form-thankYou{
    position: fixed;
    top: 80px;
}

.radio-button-div{
    width: 100%;

    @include tablet{
        width: 50%;
    }

    .radio-div-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .input-div{
        margin-bottom: 10px;
    }

    .newRadioButton{
        background-color: #FCFBF7;
        border: #0D276C solid 2px;
        border-radius: 50%;
        color: white;
        outline: none;
        min-width: 22px;
        min-height: 22px;
        display: inline-block;
        position: relative;

        i.fas.fa-circle {
            position: absolute;
            top: 51%;
            left: 50%;
            color: rgb(13, 38, 108);
            transform: translate(-50%, -50%);
        }
    }

    .newRadioButton:hover{
        cursor: pointer;
    }

    label{
        margin-right: 20px;
        margin-left: 5px;
    }

    label:hover{
        cursor: pointer;
    }
}

.input-div-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include tablet{
        margin-top: 30px;
        width: 50%;
    }

    @include mini-desktop{
        margin-top: 15px;
    }

    .input-div{
        margin-bottom: 10px;

        .send-form-input{
            background-color: #FCFBF7;
            border: #0D276C solid 2px;
            border-radius: 5px;
            color: #0D276C;
            outline: none;
            height: 30px;
            width: 230px;
            margin-right: 30px;
            
            @include mini-desktop{
                margin: 15px 0 1px 0;
            }
        }
        @include tablet{
            margin-bottom: 30px;
        }

        @include mini-desktop{
            margin-bottom: 10px;
        }
        
        .send-form-input::placeholder{
            color: #0D276C;
        }

        .input-error-message {
            width: 200px;
            font-size: 13px;
        }
    }

    @include tablet{
        display: block;
    }
}

.button-div{
    display: block;
    flex-direction: row;
    margin-top: 10px;

    .send-form-button{
        padding: 0;
        background-color: #fcfbf7 ;
        color: #0D276C;
        border: none;
        text-decoration: underline;
        font-size: 25px;
        outline: none;
    }

    .send-form-button:hover{
        cursor: pointer;
    }
}