// Mixins
@mixin phone {
    @media (max-width: 600px) { @content; }    
}
@mixin mini-tablet {
    @media (min-width: 600px) { @content; }    
}
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}
@mixin desktop {
    @media (min-width: 820px) { @content; }    
}

.split-map-block{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    
    @include desktop{
        flex-direction: row-reverse;
    }
    
    .split-map-content-Left{
        left: 0;
    }
    .split-map-content-Right{
        transform: translate(100%);
    }

    .split-map-content-Blue{
        background-color: #0D276C;
    }
    .split-map-content-White{
        background-color: #FCFBF7;
        opacity: 80%;
    }

    .split-map-content-wrapper{
        width: 100%;
        box-sizing: border-box;
        padding-top: 40px;
        padding-bottom: 40px;

        @include desktop{
            width: 50%;
            min-height: 100%;
        }

        .content-padding{
            padding: 50px 0;

            @include desktop{
                padding: 0;
            }
            
            .split-map-ingress{
                padding-bottom: 50px;
            }            

        }

        .split-map-button-div{

            .split-map-button{
                background-color: transparent;
                color: #fcfbf7;
                border: none;
                text-decoration: underline;
                font-size: 29px;
                margin: 20px 20px 20px 0;
                padding: 5px 5px 5px 0;
                outline: none;
                text-align: left;
            }
            .split-map-button:hover{
                cursor: pointer;
            }
            .split-map-button:visited{
                cursor: pointer;
            }
        }
    }

    .container-map {
        width: 100%;
        height: 90vh;
        object-fit: cover;

        @include tablet{
            height: unset;
        }

        @include desktop{
            width: 50%;
        }

        .map-container {
            width: 100%;
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            @include phone{
                min-width: unset;
            }
            
            .map-image{
                height: 100%; 
                width: 100%; 
                background-size: cover; 
                background-repeat: no-repeat;
                top:0;
                padding-top: calc(1.29 * 100%);
            }

            .map {
                position: absolute;
                width: 100%;
                height: 100%;
                height: 0%;
                
                .marker-title{
                    position: absolute;
                    font-size: 18px;
                    color: #0D276C;
                    
                }
    
                .marker {
                    z-index: 5;
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #0D276C;
                }
    
                .marker:hover{
                    cursor: pointer;
                }
    
                .selectedMarker{
                    z-index: 5;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #0D276C;
                }
            
                img {
                    height: 100%;
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    &.mobile {
                        display: none;
                    }
                }  
            }
        }
    }

    .pop-up-wrapper-map{
        position: absolute;
        z-index: 5;
        width: 85%;
        background-color: #FCFBF7;
        border-radius: 5px;
        max-height: 99%;
        border: 1px solid rgba(13, 38, 108, 0.3);
        box-shadow: 3px 5px 18px #b9b9b9;
        top:50%;
        transform:translateY(-50%);
        
        @include mini-tablet{
            width: 75%;
        }
        
        .close-pop-up{
            position: absolute;
            right: 10px;
            top: 10px;
            
            .exit{
                color: rgb(13, 38, 108,); 
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #split-map-block {
        flex-direction: column;

        .split-map-content-wrapper{
            width: 100%;
        }
    
        .map-container {
            width: 50%;
            overflow: hidden;
            .map {
                img {
                    width: 100vw;
                    &.desktop {
                        display: none;
                    }
                        
                    &.mobile {
                        display: block;
                    }
                }
            }
        }
    }
}