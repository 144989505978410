// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 900px) { @content; }
}

.split-page-categories{
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    .split-page-image-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: contain;
        
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .split-page-image-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
        width: 100%;
        height: 100%;

        img{
            width: 100%;
            object-fit: cover;
        }
    }
   
    .split-page-category-content-wrapper{
        width: 98%;
        display: flex;
        flex-wrap: wrap;

        @include tablet{
            width: 50%;
            background-color: rgb(13, 38, 108);
            display: flex;
            flex-wrap: wrap;
            padding-top: 40px;
        }
        
        .split-page-text{
            padding-left: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .split-page-logo-div{
            display: flex;
            flex-direction: row;

            .logoImages-div{
                margin: 30px;
                .logoImage{
                    width: 150px;
                }
            }
        }
    }

    // Closed
    .hide{
        width: 100%;
        background-color:rgb(252, 251, 247);
        display: flex;
        flex-wrap: wrap;
        z-index: 10;

        @include tablet{
            width: 50%;
        }
        .split-page-categeory-content{
            height: 100%;
            z-index: -1;
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            width: 100%;
            padding-top: 40px;

            @include tablet{
                padding-left: 40px;
            }

            .category-title{
                width: 75%;
                cursor: pointer;
                color: rgb(13, 38, 108);
                font-family: 'Raleway', sans-serif;
                font-weight: normal;
                font-size: 24px;
                
                @include tablet{
                    font-size: 29px;
                    margin: 25px 0;
                }
            }
            .category-title:hover{
                text-decoration: underline;
            }
            .title-underline{
                text-decoration: underline;
                cursor: pointer;
            }
            .category-title-h1{
                color: rgb(13, 38, 108);
            }
        }

        .category-title-wrapper{
            display: flex;
            flex-direction: column;

            .icon-title-wrapper {
                display: flex;
                align-items: center;
            }
            
            @include tablet{
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .icon-circle{
                background-color: rgb(13, 38, 108);
                border-radius: 50%;
                display: inline-flex;
                width: 50px;
                height: 50px;
                margin-right: 15px;
                
                .icon-img{
                    flex-grow: 1;
                    flex-grow:1;
                    margin: 10px;
                    object-fit: cover;
                    height: 30px;
                    width: 30px;
                }
            }
            .split-page-category-content-wrapper{
                display: none;
            }
        }
    }

    // Open
    .category-wrapper{
        width: 100%;
        background-color:rgb(252, 251, 247);
        display: flex;
        flex-wrap: wrap;
        z-index: 9;
        
        @include tablet{
            width: 50%;
        }

        .icon-title-wrapper{
            display: flex;
            align-items: center;
        }
        
        .split-page-categeory-content{
            height: 100%;
            z-index: -1;
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            padding-top: 40px;

            @include tablet{
                padding-left: 40px;
            }

            .category-title{
                color: rgb(13, 38, 108);
                word-break: break-word;
                cursor: pointer;
                width: 75%;
                font-family: 'Raleway', sans-serif;
                font-weight: normal;
                font-size: 24px;
                
                @include tablet{
                    font-size: 29px;
                    margin: 25px 0;
                }
            }
            .category-title:hover{
                text-decoration: underline;
            }
            .title-underline{
                text-decoration: underline;
            }
            .category-title-h1{
                color: rgb(13, 38, 108);
            }
        }

        .category-title-wrapper{
            display: flex;
            flex-direction: column;
            
            @include tablet{
                align-items: center;
                flex-direction: row;
            }

            .icon-circle{
                background-color: rgb(13, 38, 108);
                border-radius: 50%;
                display: inline-flex;
                width: 50px;
                height: 50px;
                margin-right: 15px;
                
                .icon-img{
                    flex-grow: 1;
                    flex-grow:1;
                    margin: 10px;
                    object-fit: cover;
                    height: 30px;
                    width: 30px;
                }
            }
            
            .icon-title-wrapper{
                display: flex;
                align-items: center;
            }
        }
    }
    
    .ul-list{
        margin-left: 20px;
        width: 90%;
        z-index: 1;
        margin-left: 30px;
        color: rgb(13, 38, 108);
                
        @include tablet{
            color:rgb(252, 251, 247);
        }

        .content-title{
            margin-top: 45px;
            margin-left: 25px;
            text-decoration: underline;
        }
        
        .content-ingress{
            margin-left: 25px;
        }

        .listElement{
            padding: 15px;
            font-size: 22px;
            line-height: 1.4em;
            font-family: 'Raleway', sans-serif;
        }
    }
        
    .button{
        bottom: 50px;
        left: 50px;
        background-color: transparent;
        border: none;
        text-decoration: underline;
        font-size: 27px;
        margin: 20px;
        text-align: left;
        padding: 5px;
        outline: none;
        color: rgb(13, 38, 108);
        
        @include tablet{
            color: #fcfbf7;
        }
        a{
            color: rgb(13, 38, 108);
                
            @include tablet{
                    color: #fcfbf7;
                }

        }
        a:visited{
            color: rgb(13, 38, 108);    
        }
    }
    button:hover{
        cursor: pointer;
    }
}