// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }
}

.footer-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    background-color: #1C2241;

    @include tablet{
        flex-direction: row;
        justify-content: space-between;
    }

    .meny-div{
        display: flex;
        justify-content: center;

        .ul{
            list-style: none;
            text-align: center;
            padding: 0;
            padding-top: 20px;

            @include tablet{
                text-align: left;
                padding-left: 100px;
                padding-top: 15px;
            }
            a{
                color: #fcfbf7;
            }
            .li{
                color: #fcfbf7;
                margin-bottom: 10px;
                font-weight: bold;

                @include tablet{
                    font-weight: normal;
                }
            }
            .li:hover{
                text-decoration: underline;
                cursor: pointer;
            }
            .li:visited{
                color: #fcfbf7;
            }
        }
    }

    .footer-img-div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        @include tablet{
            margin-right: 80px;
        }
    }

    .footer-img{
        float: left;
        width: 20%;
        height: 45%;
        text-align: center;

        @include tablet{
            width: 50%;
        }
    }
}