@import "_form.scss";
@import "_hero.scss";
@import "_splitmap.scss";
@import "_splitpage.scss";
@import "_splitPageCategories.scss";
@import "_navbar.scss";
@import "_modal.scss";
@import "_splitpageorg.scss";
@import "_footer.scss";
@import "_map.scss";
@import "_coronaModal.scss";
@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");

// Mixins
@mixin phone {
    @media (max-width: 600px) { @content; }    
}
@mixin mini-tablet {
    @media (min-width: 600px) { @content; }    
}
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}
@mixin desktop {
    @media (min-width: 1200px) { @content; }    
}

html {
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}

h1, h2{
    font-family: 'Prata', serif;
    font-weight: 100;
    font-size: 30px;
    
    @include tablet{
        font-size: 42px;
    }
}

h3{
    font-family: 'Raleway', sans-serif;
    font-weight: normal;
    font-size: 27px;
    
    @include tablet{
        font-size: 30px;
    }
}

p{
    line-height: 1.8;
    font-family: 'Raleway', sans-serif;
    font-size: 19px;
    
    @include tablet{
        font-size: 22px;
    }
}

label{
    font-family: 'Raleway', sans-serif;
    font-size: 19px;
}

.side-by-side {
display: flex;
flex-direction: row;
background-color:#0D276C;
color: white;
}

.side-by-side a {
    color: white;
}

.side-by-side.split-page-block {
    min-height:0 !important;
}

.side-by-side-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop{
        flex-direction: row;

    }
width: 93%;
margin-left:auto;
margin-right:auto;
min-height: 70vh;
}

.side-by-side-wrapper > div {
    @include desktop{

    width: 50%;
    }
}
.side-by-side h2 {
    margin:0;
    padding:0;
    margin-bottom: 2em;
}

.side-by-side .links {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 2em;
}

