// Mixins
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}
@mixin desktop {
    @media (min-width: 1200px) { @content; }    
}

.section {
    position: relative;
    min-height: 100%;
    background-color: #FCFBF7;

    @include mini-desktop{
        min-height: 100%;
        display: flex;
        flex-direction: row;
    }
}
.org-block{
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #0D276C;
    display: flex;
    flex-direction: column;
    color: #FCFBF7;
    padding: 30px;

    @include mini-desktop{
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    
    .org-content{
        width: 100%;
        box-sizing: border-box;
        
        @include mini-desktop{
            width: 100%;
            padding: 0 30px;
        }
    }
    .select-div{
        background-color: #0D276C;
        
        .select{
            margin-top: 10px;
            background-color: #0D276C;
            border: white solid 2px; 
            border-radius: 5px;
            color: #fcfbf7;
            outline: none;
            height: 40px;
            width: 230px;
            margin-right: 30px;
        }
        
        .org-option{
            color: #FCFBF7;
            outline: none;    
        }
        
        .org-option:hover{
            background-color: rgb(55, 55, 172); 
        }
    }
    .pop-up-wrapper{
        width: 100%;
        margin: 0 auto;
        margin-top: 30px;
        min-height: 100%;
        background-color: #FCFBF7;
        border-radius: 5px;

        @include mini-desktop{
            position: absolute;
            width: 50%;
            top: 0;
            right: 0;
            margin: 0;
        }
        
        @include desktop{
            width: 50%;
        }
    
        .pop-up-info-div{
            padding: 50px;

            @include mini-desktop{
                padding: 0 60px;
            }

            .pop-up-title{
                font-size: 20px;
                font-family: 'Raleway', sans-serif;
                margin: 10px 0;
                color: rgb(13, 38, 108,); 
                font-weight: bold;
            }

            .pop-up-text{
                font-size: 15px;
                color: rgb(13, 38, 108,); 
            }

            .pop-up-img{
                width: 100%;
                height: auto;
            }
        }

        .org-button-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            .org-button{
            background-color: transparent;
            color: rgb(13, 38, 108,);
            border: none;
            text-decoration: underline;
            font-size: 22px;
            margin-top: 20px;
            padding: 5px;
            outline: none;
            }
            .org-button:hover{
                cursor: pointer;
            }
        }
        
        .close-pop-up{
            position: absolute;
            right: 10px;
            top: 10px;

            @include tablet{
                display: none;
            }
            
            .exit{
                color: rgb(13, 38, 108,); 
                cursor: pointer;
            }
        }
    }
    ul{
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;

        li{
            margin-bottom: 15px;
            font-size: 16px;
            
            @include tablet{
                width: 45%;
            }
        }

        li:hover{
            cursor: pointer;
            text-decoration: underline;
        }
        
        li.active{
            text-decoration: underline;
        }
        .org-underline{
            text-decoration: underline;
        }
    }
}

.container-map {
    width: 100%;
    height: 90vh;
    object-fit: cover;

    @include mini-desktop{
        height: unset;
        width: 50%;
    }

    .map-container {
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        @include phone{
            min-width: unset;
        }
        
        .map-image{
            height: 100%; 
            width: 100%; 
            background-size: cover; 
            background-repeat: no-repeat;
            top:0;
            padding-top: calc(1.29 * 100%);
        }

        .map {
            position: absolute;
            width: 100%;
            height: 100%;
            height: 0%;
            
            .marker-title{
                position: absolute;
                font-size: 18px;
                color: #0D276C;
                
            }

            .marker {
                z-index: 5;
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #0D276C;
            }

            .marker:hover{
                cursor: pointer;
            }

            .selectedMarker{
                z-index: 5;
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #0D276C;
            }
        
            img {
                height: 100%;
                display: block;
                width: 100%;
                object-fit: cover;
                &.mobile {
                    display: none;
                }
            }  
        }
    }
}

.pop-up-wrapper{
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #FCFBF7;
    border-radius: 5px;

    @include tablet{
        width: 50%;
        top: 0;
        right: 0;
        margin: 0;
    }

    .pop-up-info-div{
        padding: 50px;
        margin-top: 20px;

        @include tablet{
            padding: 50px;
        }

        .pop-up-title{
            font-size: 20px;
            font-family: 'Raleway', sans-serif;
            margin: 10px 0;
            color: rgb(13, 38, 108,); 
            font-weight: bold;
        }

        .pop-up-text{
            font-size: 15px;
            color: rgb(13, 38, 108,); 
        }

        .pop-up-img{
            width: 100%;
            height: auto;
        }
    }

    .org-button-wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet{
            margin-bottom: 20px;
        }

        .org-button{
        background-color: transparent;
        color: rgb(13, 38, 108,);
        border: none;
        text-decoration: underline;
        font-size: 22px;
        margin-top: 20px;
        padding: 5px;
        outline: none;
        }
        .org-button:hover{
            cursor: pointer;
        }
    }
    
    .close-pop-up{
        position: absolute;
        right: 10px;
        top: 10px;

        @include tablet{
            display: none;
        }
        
        .exit{
            color: rgb(13, 38, 108,); 
            cursor: pointer;
        }
    }
}