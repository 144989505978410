.pop-up-info-div{
    padding: 13px;

    @include mini-tablet{
        padding: 20px;  
    }
    
    .pop-up-title{
        font-size: 17px;
        font-family: 'Raleway', sans-serif;
        margin: 10px 0;
        color: black; 
        
        @include mini-tablet{
            font-size: 20px;
        }
    }

    .pop-up-text{
        font-size: 13px;
        color:black; 
        
        @include mini-tablet{
            font-size: 15px;
        }
    }

    .pop-up-image{
        width: 100%;
    }
}