.modal-wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 25px;
    left: 25px;
    border-radius: 5px;
    background-color: #FCFBF7;
    z-index: 19;
    width: 300px;
    max-height: 400px;
    padding: 20px;
    transition: 0.4s;
    border: 1px solid rgba(13, 38, 108, 0.3);
    box-shadow: 3px 5px 18px -2px #9e9d9d;
    animation: fadeinout 2s linear 1 forwards;
    font-size: 17px;
    
    @include tablet{
        width: 400px;

    }
  
    @keyframes fadeinout {
        0% { opacity: 0; }
        50% { opacity: 1; }
    }

    .close-modal{
        position: absolute;
        margin: 10px;
        right: 0;
        top: 0;

        .exit{
            color: rgb(13, 38, 108);
        }
    }

    .close-modal:hover{
        cursor: pointer;
    }

    .modal-text-div{
        text-align: center;

        .modal-text{
            color: rgb(13, 38, 108);
            font-weight: bold;
        }
    }
}

.hidden{
    display: none;
}